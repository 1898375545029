<template>
  <div class="newsView">
    <THead :centerInfo="true"></THead>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="消息列表" name="1">
        <ul class="news_content" v-if="listData.length != 0">
          <li v-for="(item,index) in listData" :key="index">{{item.noticeContent}}</li>
        </ul>
        <pagination v-show="total>0" :total="total" :page.sync="listQuery.current" :limit.sync="listQuery.size"
          @pagination="getList" />
        <!--无数据-->
        <el-empty v-if="listData.length == 0" image="http://image.ourgame.com/videoWeb/null.png" description=" ">
        </el-empty>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import THead from '@/components/head.vue';
  import pagination from '@/components/pagination.vue'
  import {
    myMessage
  } from '@/api/authority'
  export default {
    name: "newsView",
    data() {
      return {
        activeName: '1',
        listData: {},
        listQuery: {
          current: 1,
          size: 8,
        },
        total: 0
      };
    },
    components: {
      THead,
      pagination
    },
    mounted() {
      window.scrollTo(0,0)
      this.getList()
    },
    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      },
      // 获取数据
      getList(parm) {
        if (typeof parm != 'undefined') {
          this.listQuery.current = parm.page
        }
        myMessage(this.listQuery).then(response => {
          if (response.data.code == 0) {
            this.listData = response.data.data.records
            this.total = response.data.data.total
          } else {
            this.$message({
              'message': response.data.msg
            })
          }
        })
      }
    }
  }
</script>
<style lang="less">
  .newsView {
    width: 1281px;
    height: 815px;
    margin: 90px auto;
    background: white;
    padding-top: 40px;
    padding: 40px;

    .news_content {
      height: 688px;

      li {
        // width: 1229px;
        height: 65px;
        background-color: #f8f8fc;
        border-radius: 5px;
        margin: 24px 0px 0px 0px;
        text-indent: 43px;
        font-size: 14px;
        line-height: 65px;

        span {
          color: #333333;
        }
      }
    }
  }
</style>